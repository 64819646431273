<template lang="">
<div class="main">
    <div class="top-news">
      <div class="row">
        <div class="col-2">
            <h3 class="title">
              News
            </h3>
            <h2 class="sub-title">
              {{$t('top_page.list')}}
            </h2>
        </div>
        <div class="col-10">
            <ul class="list">
                <li v-for="(item, index) in informationsList.data" :key="index">
                    <Item :item="item" />
                </li>
            </ul>
        </div>
      </div>
    </div>
      <Pagination
          :length="informationsList.last_page"
        v-model="filter.page"
        @input="onPageChange"
      />
      <MetaTags/>
      </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Item from './components/item.vue';
export default {
  components: {
    Item
  },
  data() {
    return {
      filter: {
        page: 1,
      },
      pagination: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.filter = this.$router.getQuery(this.filter);
        this.$router.setQuery(this.filter);
        this.fetchTopPageInformation(this.filter);
      },
    },
  },
  computed: {
    ...mapGetters({
      informationsList: "common/getInformation",
    }),
  },
  created() {
    this.filter = this.$router.getQuery(this.filter);
    this.fetchTopPageInformation(this.filter);
  },
  methods: {
    ...mapActions({
      fetchTopPageInformation: "common/fetchTopPageInformation",
    }),
    onPageChange() {
      this.$router.setQuery(this.filter);
    },
  },
};
</script>
<style scoped>
.custom-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>